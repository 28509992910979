import Vue from 'vue'
import Login from './Login.vue'
import vuetify from '../../plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from '../../localize/i18n';

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

new Vue({
  vuetify,
  i18n,
  render: h => h(Login)
}).$mount('#app')
