











































































































































































































































import Vue from 'vue';
import * as appConfig from '../../appConfig'
import MessageBox from '../../components/common/MessageBox.vue'
import LicenseAgreementDialog from '../../components/user/LicenseAgreementDialog.vue'
import '@/assets/csj-v1.0/style.css'
import { StringUtils } from '../../utils/StringUtils'
import { ROLE_USER } from '../../dataTypes'

const option = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
};

export default Vue.extend({
  name: 'Login',

  data: () => ({
    version: appConfig.version,
    valid: false,
    loading: false,
    showPassword: false,
    customerID: "",
    password: "",
    errorMessage: "",
    showMessageBox: false,
    licenseAgreementDialogVisible: false,
    licenseText: "",
    lisenseAgreementId: "",
    showPasswordResetView: false,
    passwordResetCustomerID: "",
    passwordResetMail: "",
    requestResetPasswordComplete: false
  }),
  components: {
    MessageBox,
    LicenseAgreementDialog
  },
  computed: {
    customerIdRules() {
      return [
        (v: string) => !!v || this.$t("messages.customerid_validation"),
      ];
    },
    passwordRules() { 
      return [
        (v: string) => !!v || this.$t("messages.password_validation"),
      ]
    },
    customerMailRules(): ((v: string) => boolean | string)[] {
      return [
        (v: string) => !!v || this.$t('messages.customermail_validation') as string,
        (v: string) => !v || !!v.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/) || this.$t('messages.input_valid_mailaddress') as string,
        (v: string) => !v || v.length <= 256 || StringUtils.format(this.$t("messages.charlen_over_validation") as string, '256'),
      ]
    },
  },
  methods: {
    async closeLicenseAgreementDialog(isOK: boolean) {
      this.loading = false;
      this.licenseAgreementDialogVisible = false;

      if (isOK) {
        const data = {
          req_type: "Login",
          req_data: {
            loginId: this.customerID,
            password: this.password,
          },
          userRole: ROLE_USER
        };
        const dataStr = JSON.stringify(data);
        const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
        if (res.status == 200 && res.data.status == 0) {
          const data = {
            req_type: "AgreeLicense",
            req_data: { notificationId: this.lisenseAgreementId }
          };

          const dataStr = JSON.stringify(data);
          const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
          if (res.status == 200 && res.data.status == 0) {
            window.location.href = appConfig.baseUrl + "/";
          } else {
            window.location.href = appConfig.baseUrl + "/login";
          }
        }
      }
    },

    async login() {
      this.loading = true;
      try {
        const data = {
          req_type: "Login",
          req_data: {
            loginId: this.customerID,
            password: this.password
          },
          userRole: ROLE_USER
        };

        const dataStr = JSON.stringify(data);
        let res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
        if (res.status == 200 && res.data.status == 0) {
          const language = this.$i18n.locale == 'en' ? 1: 0;
          const dataStr = JSON.stringify({
            req_type: "GetLicenseAgreement",
            req_data: { isLogin: true, language: language }
          });
          res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
          if (res.status == 200 && res.data.status == 0) {
            this.licenseText = "";
            if (res.data.notification) {
              this.lisenseAgreementId = res.data.notification.notificationId;
              this.licenseText = StringUtils.decodeBase64(res.data.notification.body);
              this.licenseAgreementDialogVisible = true;
              
              // いったんログアウトする
              await this.logout();
            } else {
              this.loading = false;
              window.location.href = appConfig.baseUrl + "/";
            }
          } else {
            this.logout();
          }
        } else {
          if (res.status == 200 && res.data.status == -10) {
            this.errorMessage = this.$t('messages.login_account_lock') as string;
          } else {
            this.errorMessage = this.$t('messages.login_error') as string;
          }
          this.showMessageBox = true;
          this.loading = false;
        }
      } catch (err) {
          this.errorMessage = this.$t('messages.login_error') as string;
          this.showMessageBox = true;
          this.loading = false;
      }
    },
    async logout() {
      const dataStr = JSON.stringify({
        req_type: "Logout"
      });
      await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    },
    onResetPassword() {
      this.requestResetPasswordComplete = false;
      this.showPasswordResetView = true;
    },
    async onRequestResetPassword() {
      const language = this.$i18n.locale == 'en' ? 1: 0;
      const dataStr = JSON.stringify({
        req_type: "RequestResetPassword",
        req_data: {
            customerId: this.passwordResetCustomerID,
            mail: this.passwordResetMail,
            language: language
          }
      });
      this.loading = true;
      const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
      if (res.status != 200 || res.data.status != 0) {
        this.errorMessage = this.$t('messages.sendmail_failed_message') as string;
        this.showMessageBox = true;
      } else {
        this.requestResetPasswordComplete = true;
      }
      this.loading = false;
    }
  },
  mounted() {
    // (this.$refs.textField as HTMLElement).focus();
  }
});
